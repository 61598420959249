import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './IndustriesSection.css';
import MyImageComponent from './MyImageComponent';

// Import images in WebP format
import energyImg from '../assets/webp/energy.webp';
import healthcareImg from '../assets/webp/healthcare.webp';
import financeImg from '../assets/webp/finance.webp';
import retailImg from '../assets/webp/retail.webp';
import manufacturingImg from '../assets/webp/manufacturing.webp';
import educationImg from '../assets/webp/education.webp';

const industries = [
  { name: 'Energy', imgSrc: energyImg, title: 'Energy', tagline: 'Powering the Future with AI and Data Analytics', description: 'Revolutionize your energy projects with AI-driven solutions that enhance efficiency for Commercial ports, LNG Terminals, and offshore Wind projects' },
  { name: 'Healthcare', imgSrc: healthcareImg, title: 'Healthcare', tagline: 'Healing with AI. Enhancing Patient Care', description: 'Implement AI technologies to improve patient outcomes, streamline operations, and support advanced diagnostics in the healthcare sector.' },
  { name: 'Finance', imgSrc: financeImg, title: 'Finance', tagline: 'Smarter Finance. Data-Driven Financial Services', description: 'Leverage AI to enhance decision-making, mitigate risks, and personalize financial services for better customer experiences.' },
  { name: 'Retail', imgSrc: retailImg, title: 'Retail', tagline: 'Retail Reinvented. Transforming Customer Experience', description: 'Utilize AI to personalize shopping experiences, optimize supply chains, and increase customer engagement in the retail industry.' },
  { name: 'Manufacturing', imgSrc: manufacturingImg, title: 'Manufacturing', tagline: 'Intelligent Manufacturing. Enhancing Productivity', description: 'Improve manufacturing processes with AI solutions that optimize operations, reduce downtime, and boost efficiency.' },
  { name: 'Education', imgSrc: educationImg, title: 'Education', tagline: 'Future of Learning. Personalized Education with AI', description: 'Innovate educational experiences with AI-driven personalized learning, improving student outcomes and operational efficiency.' },
];

const IndustriesSection = () => {
  return (
    <div className="industries-section">
      <Container>
        <Row className="text-center my-4">
          <Col>
            <h2>Industries We Serve</h2>
            <p>We work with various industries to provide tailored AI solutions:</p>
          </Col>
        </Row>
        <Row>
          {industries.map((industry, index) => (
            <Col md={4} key={index} className="industry-item">
              <div className="industry-image">
                <img src={industry.imgSrc} alt={industry.name} className="industry-img" loading="eager" fetchpriority="high" />
              </div>
              <div className="industry-content">
                <h3>{industry.title}</h3>
                <hr className="divider" />
                <h4>{industry.tagline}</h4>
                <p>{industry.description}</p>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default IndustriesSection;
