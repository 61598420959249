import React from 'react';
import { Container, Row, Col, Card, Tabs, Tab } from 'react-bootstrap';
import MyImageComponent from './MyImageComponent'; // Import the updated MyImageComponent
import visionImg from '../assets/webp/vision.webp'; // Example image, replace with actual paths
import valuesImg from '../assets/webp/value.webp'; // Example image, replace with actual paths
import missionImg from '../assets/webp/mission.webp'; // Example image, replace with actual paths
import teamImg from '../assets/webp/team2.webp'; // Example image, replace with actual paths
import { FaIndustry, FaBrain, FaCog, FaRobot, FaComments, FaChartLine, FaMoneyBillWave, FaShoppingCart, FaGraduationCap } from 'react-icons/fa';
import './AboutUs.css';

const expertDomains = [
  { domain: 'Energy', icon: <FaIndustry size={40} /> },
  { domain: 'AI', icon: <FaBrain size={40} /> },
  { domain: 'MLOps', icon: <FaCog size={40} /> },
  { domain: 'LLMOps', icon: <FaRobot size={40} /> },
  { domain: 'Chatbot', icon: <FaComments size={40} /> },
  { domain: 'Data Science', icon: <FaChartLine size={40} /> },
  { domain: 'Finance', icon: <FaMoneyBillWave size={40} /> },
  { domain: 'Retail', icon: <FaShoppingCart size={40} /> },
  { domain: 'Education', icon: <FaGraduationCap size={40} /> },
];

const AboutUsPage = () => {
  return (
    <div className="about-us-section">
      <Container className="courses-section my-5">
        <h1 className="text-center my-5">About Us</h1>
        <Tabs defaultActiveKey="vision" id="about-us-tabs" className="mb-5">
          <Tab eventKey="vision" title="Vision">
            <div className="about-us-content-section">
              <Card className="about-us-card-horizontal">
                <Row noGutters>
                  <Col md={4}>
                    <MyImageComponent src={visionImg} alt="Our Vision" width="100%" height="auto" className="about-us-card-img" loading="eager" />
                  </Col>
                  <Col md={8}>
                    <Card.Body className="about-us-card-inner">
                      <Card.Title as="h4">Our Vision</Card.Title>
                      <Card.Text>
                        At ISIR.AI, our vision is to be at the forefront of AI innovation, delivering cutting-edge solutions that empower businesses to achieve unprecedented success. We aim to harness the power of artificial intelligence to create a smarter, more efficient, and sustainable future for all.
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </div>
          </Tab>
          <Tab eventKey="values" title="Values">
            <div className="about-us-content-section">
              <Card className="about-us-card-horizontal">
                <Row noGutters>
                  <Col md={4}>
                    <MyImageComponent src={valuesImg} alt="Our Values" width="100%" height="auto" className="about-us-card-img" loading="eager" />
                  </Col>
                  <Col md={8}>
                    <Card.Body className="about-us-card-inner">
                      <Card.Title as="h4">Our Values</Card.Title>
                      <Card.Text>
                        We value integrity, innovation, and excellence. Our team is dedicated to delivering high-quality solutions that meet the unique needs of our clients while adhering to the highest ethical standards.
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </div>
          </Tab>
          <Tab eventKey="mission" title="Mission">
            <div className="about-us-content-section">
              <Card className="about-us-card-horizontal">
                <Row noGutters>
                  <Col md={4}>
                    <MyImageComponent src={missionImg} alt="Our Mission" width="100%" height="auto" className="about-us-card-img" loading="eager" />
                  </Col>
                  <Col md={8}>
                    <Card.Body className="about-us-card-inner">
                      <Card.Title as="h4">Our Mission</Card.Title>
                      <Card.Text>
                        Our mission is to empower businesses with AI-driven solutions that enhance decision-making, optimize operations, and drive growth. We are committed to helping our clients achieve their goals through innovative technology and exceptional service, all at an affordable cost.
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </div>
          </Tab>
          <Tab eventKey="team" title="Our Team">
            <div className="about-us-content-section">
              <Card className="about-us-card-horizontal">
                <Row noGutters>
                  <Col md={4}>
                    <MyImageComponent src={teamImg} alt="Our Team" width="100%" height="auto" className="about-us-card-img" loading="eager" />
                  </Col>
                  <Col md={8}>
                    <Card.Body className="about-us-card-inner">
                      <Card.Title as="h4">Our Team</Card.Title>
                      <Card.Text>
                        Our team of experts have wonderful experience in their respective fields. They bring a wealth of knowledge and expertise to deliver world-class AI solutions. Our professionals are committed to excellence and passionate about driving innovation in the AI industry.
                      </Card.Text>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </div>
            <hr className="divider" />
            <div className="experts-section">
              <Row>
                {expertDomains.map((expert, index) => (
                  <Col md={4} key={index} className="mb-4">
                    <Card className="expert-card">
                      <Card.Body className="expert-card-inner">
                        <div className="icon-container">{expert.icon}</div>
                        <Card.Title as="h5">{expert.domain}</Card.Title>
                        <Card.Text>
                          Our {expert.domain} experts have extensive experience in delivering innovative solutions and driving success in their respective fields.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>
          </Tab>
        </Tabs>
      </Container>
    </div>
  );
};

export default AboutUsPage;
