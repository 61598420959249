import React from 'react';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { FaCloud, FaChartLine, FaRobot, FaCogs, FaUserAlt } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Service.css'; // Ensure this CSS file is imported
import MyImageComponent from './MyImageComponent';

// Import images
import cloud from '../assets/webp/cloud.webp';
import ai from '../assets/webp/ai.webp';
import dev from '../assets/webp/dev.webp';
import chat from '../assets/webp/chat.webp';
import data from '../assets/webp/data.webp';

const services = [
  {
    icon: <FaRobot size={40} />,
    title: "AI Consultancy",
    frontText: "AI Consultancy",
    backText: "Transform your business with AI-driven strategies.",
    backgroundImage: ai,
    link: "/services#ai-consultancy",
  },
  {
    icon: <FaChartLine size={40} />,
    title: "Data Analytics",
    frontText: "Data Analytics",
    backText: "Unlock the power of data with advanced analytics.",
    backgroundImage: data,
    link: "/services#data-analytics",
  },
  {
    icon: <FaUserAlt size={40} />,
    title: "Chatbot Services",
    frontText: "Chatbot Services",
    backText: "Enhance customer engagement with intelligent chatbots.",
    backgroundImage: chat,
    link: "/services#chatbot-services",
  },
  {
    icon: <FaCloud size={40} />,
    title: "Cloud Solutions",
    frontText: "Cloud Solutions",
    backText: "Scalable and secure cloud integration services.",
    backgroundImage: cloud,
    link: "/services#cloud-solutions",
  },
  {
    icon: <FaCogs size={40} />,
    title: "DevOps Services",
    frontText: "DevOps Services",
    backText: "Streamline your development process with DevOps.",
    backgroundImage: dev,
    link: "/services#devops-services",
  },
];

const Card = ({ card }) => {
  const navigate = useNavigate();

  const handleLinkClick = (e, link) => {
    e.preventDefault();
    const path = link.split('#')[0];
    const hash = link.split('#')[1];

    navigate(path, { state: { hash } });

    setTimeout(() => {
      const element = document.getElementById(hash);
      if (element) {
        const offset = 100; // Adjust this value as needed
        const bodyRect = document.body.getBoundingClientRect().top;
        const elementRect = element.getBoundingClientRect().top;
        const elementPosition = elementRect - bodyRect;
        const offsetPosition = elementPosition - offset;

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }, 500); // Adding a delay of 500ms
  };

  return (
    <div className="service-card-container">
      <motion.div
        className="service-help-block"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <div
          className="service-flip-card"
          style={{ backgroundImage: `url(${card.backgroundImage})` }}
        >
          <div className="service-flip-card-inner">
            <div className="service-flip-card-front">
              <div className="service-flip-card-front-content">
                <h4>{card.title}</h4>
              </div>
            </div>
            <div className="service-flip-card-back">
              {card.icon}
              <h3>{card.frontText}</h3>
              <p>{card.backText}</p>
              <Button
                variant="light"
                onClick={(e) => handleLinkClick(e, card.link)}
              >
                Expand
              </Button>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const EnhancedServicesSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 700,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          vertical: true,
        },
      },
    ],
  };

  return (
    <div className="ourservices">
      <div className="text-center my-4">
        <h1>Our Services</h1>
        <p>Explore our range of AI solutions designed to help your business grow.</p>
      </div>
      <div className="service-card-container">
      <Slider {...settings}>
        {services.map((service, index) => (
          <Card key={index} card={service} />
        ))}
      </Slider>
      </div>
    </div>
  );
};

export default EnhancedServicesSlider;
