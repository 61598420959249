import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyImageComponent = ({ src, alt, width, height, className, loading }) => (
  <LazyLoadImage
    src={src}
    alt={alt}
    width={width}
    height={height}
    className={className}
    effect="blur"
    loading={loading}
  />
);

MyImageComponent.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  className: PropTypes.string,
  loading: PropTypes.string,
};

MyImageComponent.defaultProps = {
  width: '100%',
  height: 'auto',
  className: '',
  loading: 'lazy',
};

export default MyImageComponent;
