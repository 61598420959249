import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import MyImageComponent from './MyImageComponent'; // Import the updated MyImageComponent
import careerImg from '../assets/webp/career.webp'; // Replace with actual WebP image path
import './Courses.css'; 
import logo from '../assets/webp/logo.webp'; // Replace with actual WebP image path

const CareerCard = ({ title, description, image }) => {
  return (
    <Card className="course-card">
      <div className="course-card-inner">
        <MyImageComponent src={logo} alt="ISIR.AI Logo" width="60" height="40" className="d-inline-block align-top" loading="eager" />
        <div className="course-card-front">
          <MyImageComponent src={image} alt={title} className="card-img-top" loading="eager" />
          <Card.Body>
            <Card.Title>{title}</Card.Title>
            <Card.Text className="coming-soon">We are hiring!</Card.Text>
            <Card.Text>{description}</Card.Text>
            <Card.Text>
              Share your CV with us at <a href="mailto:career@isir.com">career@isir.com</a> and tell us why you would like to join ISIR. Share your passion, key skills, and domain expertise with us.
            </Card.Text>
          </Card.Body>
        </div>
      </div>
    </Card>
  );
};

const CareerPage = () => {
  return (
    <Container className="courses-section my-5">
      <Row className="justify-content-center">
        <Col md={8} lg={6}>
          <h1 className="text-center">Career Opportunities</h1>
          <p className="text-center">We are looking for young and experienced talent in various domains. Join our team and make an impact!</p>
          <CareerCard
            title="Join Our Team"
            description="We are seeking both young and seasoned professionals across various domains, particularly in cutting-edge technologies like AI, Cloud, and Data Engineering. We are also looking for domain-specific experts with experience in industries such as Energy, Education, Ecommerce, Retail, Healthcare, Research and Business Development

            If you have a passion for AI and possess expertise in your field, we want to hear from you! We are looking for domain experts capable for creating, transforming, and offering courses to help others learn and grow."
            image={careerImg}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CareerPage;
